<template>
  <div>
    <router-view />
    <br />
    <footer class="page-footer grey darken-4">
      <div class="container">
        <div class="row">
          <div class="col l12 s12">
            <img
              alt="Vue logo"
              src="./assets/LOGOSJUNTOS.png"
              class="responsive-img"
            />
          </div>
        </div>
      </div>
      <br>
      <div class="footer-copyright grey darken-4">
        <div class="container">
          <h6>EEMVCCH</h6>
          <br />
          <a
            href="https://www.instagram.com/eemvcch/"
            target="_blank"
            class="brand-logo"
          >
            <img alt="Vue logo" src="./assets/IGblanco.png" height="30" />
          </a>
        </div>
      </div>
      <br />
    </footer>
  </div>
</template>
<script>
export default {};
</script>
<style>
#app {
  font-family: Futura, Book font, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
