<template>
  <div class="home">
    <br />
    <br />

    <div class="container">
      <h5>EXPERIENCIAS DEL ESPACIO MODERNO EN EL VALLE CENTRAL DE CHILE</h5>
      <br />
      <br />
      <div class="row1">
        <p class="">
          Al Valle Central de Chile se le asocia un imaginario que es propio de
          la cultura agraria con que se funda el país. Siendo ello innegable,
          podemos decir también que dicha cultura agraria ha ido evolucionando
          desde hace algunos años hacia un tipo de organización, objetos y
          construcciones de raíz industrial. Esta realidad que influye hoy en
          gran parte de nuestro quehacer, lleva implícito una manera de
          vivenciar los espacios que es muy distinta a la que predominara hasta
          hace tan solo un siglo atrás.
          <br />
          <br />
          El surgimiento de materiales totalmente nuevos y eficientes como el
          hormigón armado y el acero, influyó rápidamente en todo tipo de
          construcciones, produciendo un considerable aligeramiento y autonomía
          tanto de los elementos estructurales como de los de cerramiento.
          <br />
          <br />
          Fue así como durante el Siglo XX aparecieron en distintas partes del
          mundo obras de arquitectura que se apoyaban en delgados pilares y
          muros, dejando muchas veces en sus fachadas generosas superficies
          vacías o vidriadas. Aparecía así en la arquitectura una interacción
          simultánea entre el interior y el exterior nunca antes vista.
          <br />
          <br />
          A su vez, estos avances constructivos extendieron radicalmente los
          límites de lo posible, propiciando un replanteamiento en la manera de
          concebir la arquitectura. Los tipos normativos clásicos que
          acompañaron a la arquitectura durante toda su evolución quedaban esta
          vez obsoletos, siendo sustituidos por la riqueza formal y espacial que
          emanaba directamente de la intensa y amplia capacidad configuradora de
          los nuevos materiales.
          <br />
          <br />
        </p>
        <p class="">
          Este cambio de paradigma, supuso la crisis de las asociaciones
          evocativas y preestablecidas con que por siglos el mundo clásico había
          reconocido las formas, abriéndose por primera vez en la historia un
          área de parámetros estéticos que le eran directamente propios a la
          naturaleza sensorial del ser humano, habiendo en ello –sobre todo a
          principios del Siglo XX– un marcado énfasis en el sentido de la
          visión.
          <br />
          <br />
          De este modo, una obra de arquitectura comenzaba a ser reconocida como
          tal, cuando su entramado de elementos adquiría coherencia ante el
          cuerpo y la mirada del ser humano, en tanto que marco físico-espacial
          que facilita y da dignidad a la vida por medio de un sistema de
          relaciones compuesto por formas, dimensiones, proporciones,
          posiciones, vínculos, texturas, cromatismos, luminosidades, etc.
          <br />
          <br />
          De aquí entonces la importancia de reunir y poner en público
          conocimiento un tipo de experiencia del espacio que también alcanzó
          destacadas manifestaciones en ciudades como Rancagua, Curicó, Talca,
          San Javier, Linares, Cauquenes, Chillán y Los Ángeles; las que
          constituyen entre si una base y punto de partida al desarrollo de una
          nueva tradición arquitectónica en el Valle Central de Chile.
          <br />
          <br />
          La esencia de estas vivencias espaciales, ha llegado hasta aquí
          gracias a unos medios que son afines con la problemática en cuestión.
          La intelección sensible y el respectivo énfasis en la intelección
          visual que proponen este tipo de obras, se ha podido capturar,
          reproducir e interpretar con el formato de micro documental con que a
          continuación se presentan.
        </p>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
    <div class="container">
      <div class="row">
        <div class="col s12">
          <p>
            <font size="2">
              Autor: Glenn Deulofeu, profesor de la Escuela de Arquitectura de
              la Universidad de Talca.<br />
              Trabajo realizado en conjunto con el Centro de Documentación
              Patrimonial de la Universidad de Talca.<br />
              Proyecto financiado íntegramente por el “Fondo para la Creación
              Artística” de la Universidad de Talca. (2017 - 2021).
            </font>
          </p>
        </div>
      </div>
    </div>

    <div class="container">
      <br /><br />

      <div class="row">
        <div class="container col l4 s12">
          <div class="video-container">
            <video
              class="fm-video video-js vjs-16-9 vjs-big-play-centered"
              data-setup="{}"
              controls
              preload="auto"
              poster="../assets/Postervideos/1.IglesiadelVerboDivino.jpg"
              id="fm-video2"
            >
              <source src="../assets/videos/VerboDivino.mp4" type="video/mp4" />
            </video>
          </div>
          &nbsp;
        </div>
        <div class="col l4 s12">
          <div class="video-container">
            <video
              class="fm-video video-js vjs-16-9 vjs-big-play-centered"
              data-setup="{}"
              controls
              preload="auto"
              poster="../assets/Postervideos/2.CatedraldeChillan.jpg"
              id="fm-video2"
            >
              <source
                src="../assets/videos/CatedralChillan.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          &nbsp;
        </div>
        <div class="col l4 s12">
          <div class="video-container">
            <video
              class="fm-video video-js vjs-16-9 vjs-big-play-centered"
              data-setup="{}"
              controls
              preload="auto"
              poster="../assets/Postervideos/3.COPELEC.jpg"
              id="fm-video"
            >
              <source
                src="../assets/videos/EdificioCopelec.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          &nbsp;
        </div>
      </div>

      <div class="row">
        <div class="container col l4 s12">
          <div class="video-container">
            <video
              class="fm-video video-js vjs-16-9 vjs-big-play-centered"
              data-setup="{}"
              controls
              preload="auto"
              poster="../assets/Postervideos/4.MunicipalidaddeCauquenes.jpg"
              id="fm-video2"
            >
              <source
                src="../assets/videos/MunicipalidadCauquenes.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          &nbsp;
        </div>
        <div class="col l4 s12">
          <div class="video-container">
            <video
              class="fm-video video-js vjs-16-9 vjs-big-play-centered"
              data-setup="{}"
              controls
              preload="auto"
              poster="../assets/Postervideos/5.CorreosdeLinares.jpg"
              id="fm-video2"
            >
              <source
                src="../assets/videos/EdificioCorreos.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          &nbsp;
        </div>
        <div class="col l4 s12">
          <div class="video-container">
            <video
              class="fm-video video-js vjs-16-9 vjs-big-play-centered"
              data-setup="{}"
              controls
              preload="auto"
              poster="../assets/Postervideos/6.HotelTurismoLinares.jpg"
              id="fm-video"
            >
              <source
                src="../assets/videos/HotelturismoLinares.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          &nbsp;
        </div>
      </div>

      <div class="row">
        <div class="container col l4 s12">
          <div class="video-container">
            <video
              class="fm-video video-js vjs-16-9 vjs-big-play-centered"
              data-setup="{}"
              controls
              preload="auto"
              poster="../assets/Postervideos/7.ParroquiadeSanJavier.jpg"
              id="fm-video"
            >
              <source
                src="../assets/videos/ParroquiaSanJavier.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          &nbsp;
        </div>
        <div class="col l4 s12">
          <div class="video-container">
            <video
              class="fm-video video-js vjs-16-9 vjs-big-play-centered"
              data-setup="{}"
              controls
              preload="auto"
              poster="../assets/Postervideos/8.CompaniadeFosforos.jpg"
              id="fm-video"
            >
              <source
                src="../assets/videos/Companiafosforo.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          &nbsp;
        </div>
        <div class="col l4 s12">
          <div class="video-container">
            <video
              class="fm-video video-js vjs-16-9 vjs-big-play-centered"
              data-setup="{}"
              controls
              preload="auto"
              poster="../assets/Postervideos/9.EdificioLircay.jpg"
              id="fm-video"
            >
              <source
                src="../assets/videos/EdificioLircay.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          &nbsp;
        </div>
      </div>
      <div class="row">
        <div class="col l4 s12">
          <div class="video-container">
            <video
              class="fm-video video-js vjs-16-9 vjs-big-play-centered"
              data-setup="{}"
              controls
              preload="auto"
              poster="../assets/Postervideos/10.CentrodeExtensionUTALCA.jpg"
              id="fm-video"
            >
              <source
                src="../assets/videos/EdificioextensioUtal.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          &nbsp;
        </div>
        <div class="col l4 s12">
          <div class="video-container">
            <video
              class="fm-video video-js vjs-16-9 vjs-big-play-centered"
              data-setup="{}"
              controls
              preload="auto"
              poster="../assets/Postervideos/11.EdificioEMPARTTalca.jpg"
              id="fm-video"
            >
              <source
                src="../assets/videos/EdificioEmpart.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          &nbsp;
        </div>
        <div class="col l4 s12">
          <div class="video-container">
            <video
              class="fm-video video-js vjs-16-9 vjs-big-play-centered"
              data-setup="{}"
              controls
              preload="auto"
              poster="../assets/Postervideos/12.EdificioCORVI.jpg"
              id="fm-video"
            >
              <source
                src="../assets/videos/EdificioCorviU.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          &nbsp;
        </div>
      </div>
      <div class="row">
        <div class="col l4 s12">
          <div class="video-container">
            <video
              class="fm-video video-js vjs-16-9 vjs-big-play-centered"
              data-setup="{}"
              controls
              preload="auto"
              poster="../assets/Postervideos/13.CampusLircayUTALCA.jpg"
              id="fm-video"
            >
              <source
                src="../assets/videos/EdificiosFundacional.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          &nbsp;
        </div>
        <div class="col l4 s12">
          <div class="video-container">
            <video
              class="fm-video video-js vjs-16-9 vjs-big-play-centered"
              data-setup="{}"
              controls
              preload="auto"
              poster="../assets/Postervideos/14.LiceoFernandoLazcano.jpg"
              id="fm-video"
            >
              <source
                src="../assets/videos/LiceoFernando.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          &nbsp;
        </div>
        <div class="col l4 s12">
          <div class="video-container">
            <video
              class="fm-video video-js vjs-16-9 vjs-big-play-centered"
              data-setup="{}"
              controls
              preload="auto"
              poster="../assets/Postervideos/15.EdificioFIAT.jpg"
              id="fm-video"
            >
              <source
                src="../assets/videos/EdificioFiat.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          &nbsp;
        </div>
      </div>
    </div>
  </div>
</template>
		
	
<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
};
</script>
<style>
.fm-video {
  width: 100%;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
}

.fm-video .vjs-control-bar {
  background: #f4f4f4;
  color: #080808;
}

.fm-video .vjs-play-progress,
.fm-video .vjs-volume-level {
  background: #424242;
}

.fm-video .vjs-slider {
  background: #ffffff;
}

.video-js:hover .vjs-big-play-button {
  background: rgba(0, 0, 0, 0.2);
}

.fm-video .vjs-big-play-button {
  background: rgba(0, 0, 0, 0.2);
}

.fm-video .vjs-big-play-button:hover {
  background: rgba(252, 249, 249, 0.479);
}

.vjs-has-started .vjs-poster {
  display: none;
}

.vjs-ended .vjs-poster {
  display: inline-block;
}
p.izquierda {
  margin-left: 6px;
}
p.derecha {
  margin-right: 6px;
}
p {
  text-align: justify;
  text-justify: inter-word;
  line-height: 1.7;
  margin: 0;
}
h5 {
  text-align-last: justify;
  text-align: justify;
  column-span: all;
}
.row1 {
  column-count: 2;
  column-gap: 4em;
  column-width: 250px;
}
</style>